import Link from 'next/link'

export function Footer() {
  return (
    <div className="mx-auto w-full container md:px-8 text-sm">
      <footer className="bg-gray-100 grid grid-cols-1 gap-8 md:grid-cols-4 md:gap-24 p-8 md:p-16 rounded-t-xl md:grid-rows-1">
        <div className="text-gray-500 md:col-start-2 md:col-end-3">
          <h4 className="text-base mb-2 font-bold text-gray-700">Solutions</h4>
          <ul className="mb-8 tracking-wider">
            <li>
              <Link href="/solutions/for-home-buyers">
                <a className="hover:underline">Home Buyers</a>
              </Link>
            </li>
            <li>
              <Link href="/solutions/for-home-sellers">
                <a className="hover:underline">Home Sellers</a>
              </Link>
            </li>
            <li>
              <Link href="/solutions/for-real-estate-agents">
                <a className="hover:underline">Real Estate Agents</a>
              </Link>
            </li>
            <li>
              <Link href="/solutions/for-home-inspectors">
                <a className="hover:underline">Home Inspectors</a>
              </Link>
            </li>
            <li>
              <Link href="/solutions/for-property-investors">
                <a className="hover:underline">Property Investors</a>
              </Link>
            </li>
          </ul>
          <h4 className="text-base mb-2 font-bold text-gray-700">Features</h4>
          <ul className="tracking-wider">
            <li>
              <Link href="/features/domoreport">
                <a className="hover:underline">Domoreport</a>
              </Link>
            </li>
            <li>
              <Link href="/features/documents">
                <a className="hover:underline">Documents</a>
              </Link>
            </li>
            <li>
              <Link href="/features/explore">
                <a className="hover:underline">Explore</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-gray-500 md:col-start-3 md:col-end-4">
          <h4 className="text-base mb-2 font-bold text-gray-700">Discover</h4>
          <ul className="mb-8 tracking-wider">
            <li>
              <Link href="/pricing">
                <a className="hover:underline">Pricing</a>
              </Link>
            </li>
            <li>
              <Link href="/about">
                <a className="hover:underline">About Us</a>
              </Link>
            </li>
            <li>
              <Link href="https://blog.majordomo.com">
                <a className="hover:underline">Blog</a>
              </Link>
            </li>
            <li>
              <Link href="/agent-toolkit">
                <a className="hover:underline">Agent Toolkit</a>
              </Link>
            </li>
            <li>
              <Link href="/newsroom">
                <a className="hover:underline">Newsroom</a>
              </Link>
            </li>
          </ul>
          <h4 className="text-base mb-2 font-bold text-gray-700">My Account</h4>
          <ul className="tracking-wider">
            <li>
              <Link href="/login">
                <a>Log In</a>
              </Link>
            </li>
            <li>
              <Link href="/signup">
                <a>Sign Up</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-gray-500 md:col-start-4 md:col-end-5">
          <h4 className="text-base mb-2 font-bold text-gray-700">
            Help Center
          </h4>
          <ul className="mb-8 tracking-wider">
            <li>
              <Link href="https://intercom.help/majordomo/en/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </Link>
            </li>
            <li>
              <Link href="/contact-us">
                <a className="hover:underline">Contact Us</a>
              </Link>
            </li>
            <li>
              <Link href="/terms-of-service">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </Link>
            </li>
            <li>
              <Link href="/privacy-policy">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Link>
            </li>
          </ul>
          <h4 className="text-base mb-2 font-bold text-gray-700">Connect</h4>
          <ul className="tracking-wider">
            <li>
              <Link href="https://www.facebook.com/majordomohq/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.linkedin.com/company/majordomohq/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.youtube.com/channel/UCAPucIfzWYlAXL4siJKEYWw">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:col-start-1 md:col-end-2 md:row-start-1 md:row-end-1 pt-8 md:pt-0 flex flex-col justify-center md:justify-start">
          <img src="/logo/logo.svg" className="mb-4" />
          <p className="text-xs text-center">
            © Copyright {new Date().getFullYear()} | All rights reserved
          </p>
        </div>
      </footer>
    </div>
  )
}
