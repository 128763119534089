import Link from 'next/link'
import { useState, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import styles from './Dropdown.module.css'

interface DropdownProps {
  label?: string
  links?: Array<{
    label: string
    href: string
  }>
}

export const dropdownVariants = {
  hidden: {
    opacity: 0,
    y: -20
  },
  visible: {
    opacity: 1,
    y: 0
  }
}

export function Dropdown(props: DropdownProps) {
  const [showMenu, setShowMenu] = useState(false)
  const label = useRef<HTMLSpanElement>(null)
  const menu = useRef<HTMLDivElement>(null)

  return (
    <div
      onPointerEnter={() => setShowMenu(true)}
      onPointerLeave={() => setShowMenu(false)}
      className="relative p-2"
    >
      <span ref={label} className={styles.label}>
        {props.label}
      </span>
      <AnimatePresence>
        {showMenu && props.links?.length && (
          <motion.div
            ref={menu}
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`${styles.menu} left-0`}
          >
            {props.links?.map(link => (
              <Link href={link.href} key={link.href}>
                <a className={styles.link}>{link.label}</a>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
